import React, { useState, useEffect, useRef } from "react";
import './NavigationCss.scss'
import { Link, useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { CgMenuGridO } from "react-icons/cg";
import Logo from "../../../assets/logo/nav-logo.png";
import NavLogo from "../../../assets/logo/Logo - Metaluck 2024.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export const Navigation = ({ menuColor }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isResourcesDropdownOpen, setIsResourcesDropdownOpen] = useState(false);

  const aboutRef = useRef();
  const resourcesRef = useRef();

  const navigate = useNavigate();

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const toggleAboutDropdown = () => {
    setIsAboutDropdownOpen((prev) => !prev);
  };

  const toggleResourcesDropdown = () => {
    setIsResourcesDropdownOpen((prev) => !prev);
  };

  const closeDropdowns = () => {
    setIsAboutDropdownOpen(false);
    setIsResourcesDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (aboutRef.current && !aboutRef.current.contains(event.target)) {
      setIsAboutDropdownOpen(false);
    }
    if (resourcesRef.current && !resourcesRef.current.contains(event.target)) {
      setIsResourcesDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    closeDropdowns();
    if (isMobileNavOpen) {
      setMobileNavOpen(false);
    }
  };

  const NavigateToHome = () => {
    navigate("/");
  };

  return (
      <>
        <div className="navigation-container">
          <img onClick={NavigateToHome} src={NavLogo} alt="Metaluck-logo" />
          <div className="nav-links">
            <Link to="/" onClick={handleLinkClick}>HOME</Link>
            <div  className={`dropdown ${isAboutDropdownOpen ? "open" : ""}`}>
              <div className="dropdown-toggle" onClick={toggleAboutDropdown}>
                ABOUT
                {isAboutDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div  className="dropdown-menu" >
                <Link to="/about" onClick={handleLinkClick}>About</Link>
                <Link to="/about/governance" onClick={handleLinkClick}>Governance</Link>
                <Link to="/about/team" onClick={handleLinkClick}>Team</Link>
                <Link to="/about/ourDifference" onClick={handleLinkClick}>Our Difference</Link>
              </div>
            </div>
            <Link to="/service" onClick={handleLinkClick}>SERVICES</Link>
            <Link to="/sustainability-and-impact" onClick={handleLinkClick}>IMPACT</Link>
            <div  className={`dropdown ${isResourcesDropdownOpen ? "open" : ""}`}>
              <div className="dropdown-toggle" onClick={toggleResourcesDropdown}>
                RESOURCES
                {isResourcesDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div className="dropdown-menu" >
                <Link to="/resources" onClick={handleLinkClick}> Resources</Link>
                <Link to="/resources/posts" onClick={handleLinkClick}>Posts</Link>
                <Link to="/resources/articles" onClick={handleLinkClick}>Articles</Link>
              </div>
            </div>
            <Link to="/contact" onClick={handleLinkClick}>CONTACT US</Link>
          </div>
        </div>

        <div className="mobile-nav">
          <div className="close-mobile-nav">
            <img onClick={NavigateToHome} src={NavLogo} alt="Metaluck-logo" />
            {isMobileNavOpen ? (
                <IoCloseSharp color={menuColor} onClick={toggleMobileNav} />
            ) : (
                <CgMenuGridO color={menuColor} onClick={toggleMobileNav} />
            )}
          </div>
          <div className={`nav-links-mobile ${isMobileNavOpen ? "open-nav" : ""}`}>
            <Link to="/" onClick={handleLinkClick}>HOME</Link>
            <div className={`dropdown ${isAboutDropdownOpen ? "open" : ""}`}>
              <div className="dropdown-toggle" onClick={toggleAboutDropdown}>
                ABOUT
                {isAboutDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div  className="dropdown-menu">
                <Link to="/about" onClick={handleLinkClick}>About</Link>
                <Link to="/about/governance" onClick={handleLinkClick}>Governance</Link>
                <Link to="/about/team" onClick={handleLinkClick}>Team</Link>
                <Link to="/about/ourDifference" onClick={handleLinkClick}>Our Difference</Link>
              </div>
            </div>
            <Link to="/service" onClick={handleLinkClick}>SERVICES</Link>
            <Link to="/sustainability-and-impact" onClick={handleLinkClick}>IMPACT</Link>
            <div className={`dropdown ${isResourcesDropdownOpen ? "open" : ""}`}>
              <div className="dropdown-toggle" onClick={toggleResourcesDropdown}>
                RESOURCES
                {isResourcesDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div className="dropdown-menu" >
                <Link to="/resources" onClick={handleLinkClick}>Resources</Link>
                <Link to="/resources/posts" onClick={handleLinkClick}>Posts</Link>
                <Link to="/resources/articles" onClick={handleLinkClick}>Articles</Link>
              </div>
            </div>
            <Link to="/contact" onClick={handleLinkClick}>CONTACT US</Link>
          </div>
        </div>
      </>
  );
};
