import { React, useState } from "react";
import './OurDifferenceCss.scss'
import office from "../../assets/metaluckImages/teamPageBanner.jpg";
import HeaderComponent from "../header/HeaderComponent";
import ContactCard from "../cards/ContactCard/ContactCard";
import KeyComponentCard from "../cards/KeyComponentCard/KeyComponentCard";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { images } from "../../utils/OtherImages";
import { FaArrowRight } from "react-icons/fa";
import BottomServiceCard from "../cards/BottomServiceCard/BottomServiceCard";


export const OurDifference = () => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const navigate = useNavigate();

  const handleServiceBtn = () => {
    navigate("/service");
  };

  const points = [
    {id: 1, text: "We invite collaboration, believing that joint efforts lead to greater achievements."},
    {id: 2, text:"We appreciate diversity, drawing on various backgrounds and ideas to enrich our initiatives."},
    {id: 3, text:"We strive for harmony, finding balance and peace even amidst challenges."},
    {id: 4, text:"We embody resilience, turning every setback into a new opportunity for advancement."},
    {id: 5, text:"We build trust through transparency, ensuring that all actions are accountable and open for scrutiny."},

  ];
 const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % points.length);
  };

  return (
    <>
      <HeaderComponent image={images.temHead} text={"OUR DIFFERENCE"} />
      <div className="first-difference-content">
        <p>
          In an era where sustainability defines the future, our organization
          emerges as a transformative force committed to circular innovation. At
          the core of our mission, we are dedicated to revolutionizing the
          metallurgical industry by converting waste into valuable raw
          materials, thereby fostering a regenerative economy that benefits both
          the planet and its people.
        </p>
        <div className="key-component">
            <KeyComponentCard
                image={images.purpose}
                altText={"Snow-covered mountains"}
                title={`Our Purpose`}
                text={`To make circular innovation a cornerstone for global well-being `}
            />
          <KeyComponentCard
            image={images.vision}
            altText={"Stages of seed growth"}
            title={`Our Vision`}
            text={`To live in a world transformed by a regenerative economy for the wellbeing of planet & People.`}
          />
          <KeyComponentCard
            image={images.mission}
            altText={"Employees"}
            title={`Our Mission`}
            text={`We provide sustainable solutions to the metallurgical industry by Converting waste into Raw materials.`}
          />
        </div>
      </div>

      <div className="second-difference-content">
        <div className="heading"><h2>CORE VALUES</h2></div>
        <ul>
          <li>
            <span className="core-value-title">Resilience</span> <br/><span className="core-value-description">We view challenges as catalysts for growth, transforming obstacles into stepping stones towards our goals.</span>
          </li>
          <li>
            <span className="core-value-title">Collaboration</span> <br/><span className="core-value-description">We thrive on teamwork and are committed to achieving collective success with our stakeholders.</span>
          </li>
          <li>
            <span className="core-value-title">Harmony</span> <br/><span className="core-value-description">Our strategies are crafted to align industrial needs with environmental sustainability, promoting a balanced coexistence.</span>
          </li>
          <li>
            <span className="core-value-title">Gratitude</span> <br/><span className="core-value-description">We are profoundly grateful for our community and the opportunities to drive meaningful change.</span>
          </li>
          <li>
            <span className="core-value-title">Meritocracy</span> <br/><span className="core-value-description">Recognizing hard work and talent, we cultivate an environment where excellence is rewarded.</span>
          </li>
          <li>
            <span className="core-value-title">Integrity and Trust</span> <br/><span className="core-value-description">Transparency and honesty are the pillars of our relationships, ensuring all dealings are conducted with utmost sincerity.</span>
          </li>
          <li>
            <span className="core-value-title">Sustainability</span> <br/><span className="core-value-description">Our practices are designed to sustain not just our operations but the entire planet.</span>
          </li>
          <li>
            <span className="core-value-title">Impact and Additionality</span> <br/><span className="core-value-description">We aim for interventions that not only address immediate needs but also contribute added value that extends beyond our direct involvement.</span>
          </li>
          <li>
            <span className="core-value-title">Inclusivity</span> <br/><span className="core-value-description">Embracing diversity, we seek to include a broad range of perspectives in our journey towards innovative solutions.</span>
          </li>
          <li>
            <span className="core-value-title">Innovation</span> <br/><span className="core-value-description">Constant improvement and the pursuit of groundbreaking solutions are at the heart of our ethos.</span>
          </li>
        </ul>
      </div>


      <div class="third-difference-content">
        <h2>ENGAGEMENT <br/>AND <br/>IMPACT</h2>
       <div className="third-difference-sub">
        <h3>{points[currentIndex].text}</h3>
       </div>
       <FaArrowRight onClick={handleNext} className="arrow-pc"/>
      </div>
      <div className="third-difference-half">
        <h3>Through these principles and commitments, we continue to pioneer
          advancements in circular innovation, driving impactful change for a
          healthier, more sustainable world.</h3>
      </div>

      <BottomServiceCard
        heading={`SERVICES`}
        image={images.homeFlipImg}
        btnText={`Services`}
        // isActive={isActive}
        onClick={handleServiceBtn}
      />
    </>
  );
};
