import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import './ScrollToTopButtonCss.scss';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    // Calculate the scroll percentage
    const scrollTop = window.pageYOffset;
    const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPercent = (scrollTop / windowHeight) * 100;
    setScrollPercentage(scrollPercent);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
      <>
        {isVisible && (
            <div
                className="scroll-to-top"
                onClick={scrollToTop}
                style={{ '--scroll-percentage': `${scrollPercentage}%` }}
            >
              <FaArrowUp className="scroll-icon" />
            </div>
        )}
      </>
  );
};
export default ScrollToTopButton
