
import Alex from '../assets/team/Alex.jpg';
import Amrita from '../assets/team/Amrita.jpg'
import Carolina from '../assets/team/Carolina.jpg'
import Drake from '../assets/team/Drake.jpg'
import Marco_c1 from '../assets/team/Marco_C2_BG.png'
import Marco_f from '../assets/team/Marco_F.jpg'
import Shamla from '../assets/team/Shamla.jpg'
import Sumaiya from '../assets/team/Sumaiya.jpeg'

export const images = {
  Alex,
  Amrita,
  Carolina,
  Drake,
  Marco_c1,
  Marco_f,
  Shamla,
  Sumaiya,
};
