import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Footer} from "./view/footer/Footer";
import {Navigation} from "./view/home/navigation/Navigation";
import {Home} from "./view/home/Home";
import {About} from "./view/about/About";
import {Governance} from './view/governance/Governance';
import { Team } from './view/team/Team';
import {OurDifference} from './view/ourDifference/OurDifference'
import { Service } from './view/service/Service';
import { Impact } from './view/impact/Impact';
import { ContactUs } from './view/contactUs/ContactUs';
import { Resources } from './view/resources/Resources';
import { Posts } from './view/posts/Posts';
import { Articles } from './view/articles/Articles';
import {ScrollToTopButton } from './view/cards/scrollToTopBtn/ScrollToTopButton'

function App() {
  return (
   <Router>
     <div className="App">
         <div className="background"></div>
         <Navigation menuColor="White" />
         <Routes>
          <Route path='/' Component={Home} />
          <Route path='/about' Component={About} />
          <Route path='/about/governance' Component={Governance} />
          <Route path='/about/team' Component={Team} />
          <Route path='/about/ourDifference' Component={OurDifference} />
          <Route path='/service' Component={Service} />
          <Route path='/sustainability-and-impact' Component={Impact} />
          <Route path='/resources' Component={Resources} />
          <Route path='/resources/posts' Component={Posts} />
          <Route path='/resources/articles' Component={Articles} />
          <Route path='/contact' Component={ContactUs} />
         </Routes>
         <Footer/>
    </div>
   </Router>
  );
}

export default App;
