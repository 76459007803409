import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import './TeamMemberCardCss.scss';
import { FaLinkedin } from "react-icons/fa";

function TeamMemberCard({ image, name, position, description, linkedinUrl, altText}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded); 

    if (window.innerWidth <= 1024) {
      return null;
    } else {
      handleLinkedInClick();
    }
  };
  

  const handleLinkedInClick = (e) => {
    // e.stopPropagation(); // Prevent the parent click event from being triggered
    window.open(linkedinUrl, "_blank");
  };

  return (
    <div className={`team-content ${isExpanded ? 'expanded' : ''}`}  onClick={handleToggle} >
      <div className="position-container">
        <div className="image-container" >
          <img className="office-img" src={image} alt={altText}/>
          <FaLinkedin className="linkedin-icon"/>
        </div>
        <div className="text">
          <h3 className="employee-name">{name}</h3>
          <h3 className="employee-position">{position}</h3>
          <FaLinkedin className="linkedin-icon-mobile" onClick={handleLinkedInClick}/>

          <p className="learn-more">
            {isExpanded ? (
              <>
                Show less <IoIosArrowUp />
              </>
            ) : (
              <>
                Learn more <IoIosArrowDown />
              </>
            )}
          </p>

        </div>
      </div>
      <div className="description-container">
        <p className="description">
          <span className={`additional-content ${isExpanded ? 'expanded' : ''}`}>
            {description}

          </span>
        </p>
      </div>
    </div>
  );
}

export default TeamMemberCard;
