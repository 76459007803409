import factory from '../assets/otherImages/smokingFactory.jpg'
import logoFullWhite from '../assets/logo/logoFullWhite.png'
import collaboration from '../assets/otherImages/collaboration.jpg'
import HazardousWasteManagement from '../assets/otherImages/HazardousWasteManagement.jpg'
import rawmaterials from '../assets/otherImages/rawmaterials.jpg'
import map from '../assets/otherImages/Map.png'
import ironOre from '../assets/otherImages/ironOre.jpg'
import spongeIron from '../assets/otherImages/spongeIron.jpg'
import energyProducts from '../assets/otherImages/energyProducts.jpg'
import gspLogo from '../assets/logo/GSPLogo.png'
import mission from '../assets/otherImages/mission.jpg'
import vision from '../assets/otherImages/vision.jpg'
import stewardOwned from '../assets/otherImages/stewardOwned.jpg'
import shardOwnership from '../assets/otherImages/shardOwnership.jpg'
import Intergenerational from '../assets/otherImages/Intergenerational.jpg'
import definedPurpose from '../assets/otherImages/definedPurpose.jpg'
import purpose from '../assets/otherImages/purpose.jpg'
import articleImage from '../assets/otherImages/articleImage.png'
import  handWithFlower from "../assets/otherImages/Hand.jpg"


//metaluck images
import whoWeAre1 from '../assets/metaluckImages/whoWeAre1.jpg'
import homeFlipImg from '../assets/metaluckImages/homeFlipImg.jpg'
import ores from '../assets/metaluckImages/ores.jpg' 
import employee from '../assets/metaluckImages/employee.jpg'
import waste  from '../assets/metaluckImages/waste.jpg'
import warehouse from '../assets/metaluckImages/warehouse.jpg'
import dust from '../assets/metaluckImages/dust.jpg'
import employee2 from '../assets/metaluckImages/employee2.jpg'
import homeFlipImgNew from '../assets/metaluckImages/homeFlipImgNew.jpeg'
import contactus from '../assets/metaluckImages/contactus.jpeg'
import temHead from "../assets/metaluckImages/teamPageBanner.jpg"
import MetaluckHandbookTemplate from '../assets/metaluckImages/MetaluckHandbookTemplate.png'
import MetaluckJourneyTemplate from '../assets/metaluckImages/MetaluckJourneyTemplate.png'

export const images = {
    factory,
    logoFullWhite,
    collaboration,
    HazardousWasteManagement,
    rawmaterials,
    map,
    contactus,
    ironOre,
    spongeIron,
    energyProducts,
    gspLogo,
    mission,
    vision,
    stewardOwned,
    shardOwnership,
    Intergenerational,
    definedPurpose,
    purpose,
    temHead,
    articleImage,
    handWithFlower,

    whoWeAre1,
    homeFlipImg,
    ores,
    employee,
    waste,
    warehouse,
    dust,
    employee2,
    homeFlipImgNew,
    MetaluckHandbookTemplate,
    MetaluckJourneyTemplate,
}