import {React, useState} from 'react'
import pattern from "../../../assets/about-shape.png";
import './BottomServiceCardCss.scss'
import {images} from '../../../utils/OtherImages'

function BottomServiceCard({image, btnText, onClick, heading, isActive}) {

  return (
    <div className='contact-card'>
     <div className="heading">
          <img className="pattern" src={pattern} alt="pattern" />
          <h2>{heading}</h2>
          <img className="pattern" src={pattern} alt="pattern" />
        </div>
    <div className="contact-container">
          <div className="image-container">
            <img className="pattern" src={pattern} alt="patern" />
            <img className="office-img" src={image} alt="Services" />
          </div>
          <div>
            <button className="btn" onClick={onClick}>{btnText}</button>
            <button
              className={`btn-mobile `}
              onClick={onClick}
            >
              {btnText}
            </button>
          </div>
        </div>
        </div>
  )
}

export default BottomServiceCard