import { useState } from "react";
import heroVideo from "../../assets/Hero2.mp4";
import pattern from "../../assets/about-shape.png";
import { MdOutlineDoubleArrow } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ContactCard from "../cards/ContactCard/ContactCard";
import { useNavigate } from 'react-router-dom';
import {images} from '../../utils/OtherImages'
import { MdOutlineFlipCameraAndroid } from "react-icons/md";
import ScrollToTopButton from '../cards/scrollToTopBtn/ScrollToTopButton'

export const Home = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const handleContactBtn = () => {
    navigate('/contact');
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const handleAboutBtn = () => {
    navigate('/about');
  };

  const handleServicesBtn = (serviceId) => {
    navigate(`/service#${serviceId}`);
  };
  return (
      <>
        <div className="hero-component">
          <div className="hero-overlay"></div>
          <video src={heroVideo} autoPlay loop muted/>
          <div className="hero-text-container">
            <div className="video-overlay">
              <h1>
                <span>METALUCK </span>
                <hr/>
                {/*<br/>*/}
                SUSTAINABLE SOLUTIONS <br/>
                FOR THE METALLURGICAL INDUSTRY
              </h1>
              <div className="btns">
                <button onClick={handleAboutBtn}>ABOUT</button>
                <button onClick={handleServicesBtn}>SERVICES</button>
              </div>
            </div>

          </div>
        </div>
        <div className="third-home-content">
          <div className="text-container">
            <h2>
             Metaluck is a steward-owned, conscious business dedicated to enhancing circularity in the raw materials industry.
            </h2>
          </div>
          <div className="image-container">
            <img className="pattern" src={pattern} alt="patern"/>
            <img className="office-img" src={images.handWithFlower} alt="Our Team"/>
          </div>
        </div>
        <div className="second-home">
          <div className="second-home-content">
            <div className="text-container">
              <h2>TURNING INDUSTRIAL WASTE INTO ECONOMIC VALUE</h2><br/>
              <h3>
                Over <span>23 years </span>of experience in sourcing raw materials
                across <span>Asia, Middle East, Europe & Latin America.</span>
              </h3><br/>
            </div>

            <div className="image-container">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    {/* <img className="pattern" src={pattern} alt="pattern" /> */}
                    <img className="office-img" src={images.homeFlipImgNew} alt="Contanier"/>
                    <div className="flip-icon"><MdOutlineFlipCameraAndroid/><p>FLIP ME</p></div>

                    <div className="image-overlay"></div>
                  </div>
                  <div className="flip-card-back">
                    <div className="text-container">
                      <h3>
                        Enabled the recycling of over
                         70,000 MT of hazardous metal waste 
                        since 2018 under the <br/> Basel Convention
                      </h3>
                      {/* flipped-card-image */}
                      {/* <img className="office-img" src={images.globeHome} alt="office" /> */}

                      <div className="image-overlay"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-container-mobile">
              <div className="image-container">
                <img className="office-img" src={images.homeFlipImgNew} alt="Contanier"/>
              </div>

              <h3>
                Enabled the recycling of over
                <br/> 70,000 MT of hazardous metal waste <br/>
                since 2018 under the <br/> Basel Convention
              </h3>
              {/* <a href="/about">
              <MdOutlineDoubleArrow />
              Learn more about our stewardship business model
            </a> */}
            </div>

          </div>
        </div>
        <div className="first-home-content">
          <div className="text-container">
            <h3>70% of Green House Emissions are related to material use.</h3>
            <h3>
              Waste processing technologies are capital intensive and require
              technical expertise for implementation.
            </h3>
            {/* <h3>Turning steel industry waste into economic value.</h3> */}

          </div>
          <div className="image-container">
            <img className="pattern" src={pattern} alt="patern"/>
            <img className="office-img" src={images.factory} alt="Green house emissions"/>
          </div>
        </div>
        <div className="sixth-home-content">
          <div className="image-container">
            <img className="pattern additional-pattern" src={pattern} alt="patern"/>
            <img className="office-img additional-office-img" src={images.employee} alt="Our Team"/>
          </div>
          <div className="text-container">
            <h2>WHO WE ARE</h2>
            <h3>
              Metaluck is a conscious company working <br/>
              prioritizing the people and the planet before profits.
            </h3>
            <a href="/about">
              <MdOutlineDoubleArrow/>
              Learn more about our stewardship business model
            </a>
          </div>
        </div>
        <div className="fourth-home-content">
          <div className="text-container">
            <h3>
              With access to industry expertise and new technologies, Metaluck
              aims to elevate circularity, efficiency and sustainability in the
              metallurgical industry by fostering innovation and collaboration.
            </h3>
            <a href="/sustainability-and-impact">
              <MdOutlineDoubleArrow/>
              Learn more
            </a>
          </div>
          <div className="image-container">
            <img className="pattern" src={pattern} alt="patern"/>
            <img className="office-img" src={images.gspLogo} alt="GSP Logo"/>
          </div>
        </div>
        <div className="fifth-home-content">
          <div className="heading">
            <img className="pattern" src={pattern} alt="pattern"/>
            <h2>SERVICES</h2>
            <img className="pattern" src={pattern} alt="pattern"/>
          </div>

          <div className="services-container">
            <div className="each-service" onClick={() => handleServicesBtn('alternative-raw-materials')}>
              <img className="office-img" src={images.ores} alt="Iron Ores"/>
              {/* <FaHandPointUp className="icon"/> */}
              <div className="overlay">
                <p>Alternative raw materials</p>
              </div>
            </div>
            <div className="each-service" onClick={() => handleServicesBtn('hazardous-waste-management')}>
              <img className="office-img" src={images.employee2} alt="Hazardous waste management"/>
              {/* <FaHandPointUp className="icon"/> */}
              <div className="overlay">
                <p>Hazardous waste management</p>
              </div>
            </div>
            <div className="each-service" onClick={() => handleServicesBtn('energy-efficiency')}>
              <img className="office-img" src={images.warehouse} alt="Warehouse"/>
              {/* <FaHandPointUp className="icon"/> */}
              <div className="overlay">
                <p>Energy efficiency</p>
              </div>
            </div>
            <div className="each-service" onClick={() => handleServicesBtn('by-products')}>
              <img className="office-img" src={images.dust} alt="By products"/>
              {/* <FaHandPointUp className="icon"/> */}
              <div className="overlay">
                <p>By-products</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="carousel-container">     */}
        <Carousel
      arrows={false}
      responsive={responsive}
      showDots={true}
      swipeable={true}
      infinite={false}
      autoPlay={false}
      autoPlaySpeed={3750}
      className="fifth-home-content-mobile"
    >
      <div className="each-service" onClick={() => handleServicesBtn('alternative-raw-materials')}>
        <div className="image-container">
          <img className="office-img" src={images.ores} alt="Iron Ores" />
          <div className="overlay">
            <p>Alternative raw materials</p>
          </div>
        </div>
      </div>
      <div className="each-service" onClick={() => handleServicesBtn('hazardous-waste-management')}>
        <div className="image-container">
          <img className="office-img" src={images.employee2} alt="Hazardous waste management" />
          <div className="overlay">
            <p>Hazardous waste management</p>
          </div>
        </div>
      </div>
      <div className="each-service" onClick={() => handleServicesBtn('energy-efficiency')}>
        <div className="image-container">
          <img className="office-img" src={images.warehouse} alt="Warehouse" />
          <div className="overlay">
            <p>Energy efficiency</p>
          </div>
        </div>
      </div>
      <div className="each-service" onClick={() => handleServicesBtn('by-products')}>
        <div className="image-container">
          <img className="office-img" src={images.dust} alt="Iron Ores" />
          <div className="overlay">
            <p>By-products</p>
          </div>
        </div>
      </div>
    </Carousel>
    {/* </div> */}

        <ContactCard
            heading={`CONTACT US`}
            image={images.contactus}
            btnText={`Contact Us`}
            // isActive={isActive}
            onClick={handleContactBtn}
        />
        <ScrollToTopButton/>

      </>
  );
};
