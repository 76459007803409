import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../header/HeaderComponent";
import ContactCard from "../cards/ContactCard/ContactCard";
import ServiceContentCard from "../cards/ServiceContentCard/ServiceContentCard";
import {images} from '../../utils/OtherImages'

export const Service = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleContactBtn = () => {
    navigate('/contact');
  };

  return (
    <>
      <HeaderComponent image={images.waste} text={"SERVICES"} />

      <div className="services-container">
          <div id="alternative-raw-materials">
              <ServiceContentCard
                  title="Alternative Raw Materials"
                  items={["Pig iron Scrap", "Steel skull scrap","And more..."]}
                  description="Alternative raw materials like pig iron scrap and steel skull scrap are essential for sustainable steel production, reducing waste and conserving natural resources."
                  image={images.ores}
                  altText={"Alternative Raw Materials"}
              />
          </div>

          <div id="hazardous-waste-management">
              <ServiceContentCard
                  title="Hazardous Waste Management"
                  items={["Steel furnace dust", "Lead furnace dust","And more..."]}
                  description="We turn metal waste into circular raw materials, creating economic value and delivering positive environmental impact."
                  image={images.employee2}
                  altText={"Hazardous Waste Management"}
              />
          </div>

          <div id="energy-efficiency">
              <ServiceContentCard
                  title="Energy Efficiency"
                  items={[]}
                  description="Maximize energy efficiency in metal manufacturing with our consulting services focused on alternative raw material implementation. Reduce carbon footprint, cut costs, and enhance sustainability by integrating innovative, eco-friendly materials into your production processes. Embrace the future of manufacturing with optimized resource use."
                  image={images.warehouse}
                  altText={"Energy Efficiency"}
              />
          </div>

          <div id="by-products">
              <ServiceContentCard
                  title="By-Products"
                  items={["Iron ore pellets fines", "HBI Chips and Fines","And more..."]}
                  description="By-products such as mill scale, iron ore pellet fines, and HBI chips and fines play crucial roles in recycling and enhancing the efficiency of steelmaking."
                  image={images.dust}
                  altText={"By-Products"}
              />
          </div>
      </div>

      <ContactCard
        heading={`CONTACT US`}
        image={images.contactus}
        btnText={`Contact Us`}
        onClick={handleContactBtn}
      />
    </>
  );
};
