import { React, useState } from "react";
import './GovernanceCss.scss'
import { useNavigate } from 'react-router-dom';

import pattern from "../../assets/about-shape.png";
import HeaderComponent from "../header/HeaderComponent";
import BottomServiceCard from "../cards/BottomServiceCard/BottomServiceCard";
import KeyComponentCard from "../cards/KeyComponentCard/KeyComponentCard";
import {images} from '../../utils/OtherImages'
import "react-multi-carousel/lib/styles.css";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
export const Governance = () => {
  const [showMore, setShowMore] = useState(false);
  const [showMoreTwo, setShowMoreTwo] = useState(false);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);


  const handleServiceBtn = () => {
    navigate('/service');
  };

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  const handleToggleTwo = () => {
    setShowMoreTwo(!showMoreTwo);
  };

  const circles = [
    { id: 1, text: "Unrealistic growth expectations resulting in excessive returns, leaving viable businesses without funding if they don't fit the 'unicorn' mold."},
    { id: 2, text: "Preferred shares granting investors significant control."},
    { id: 3, text: "An 'exit' through IPOs or acquisitions, which compromises self-governance and mutual benefit." }
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % circles.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + circles.length) % circles.length);
  };

  const progress = (currentIndex + 1) * (100 / circles.length);

  return (
    <>
      <HeaderComponent
        image={images.temHead}
        text={"GOVERNANCE"}
        subText={
          "Beyond Extraction: Embracing Stewardship for a Just and Well-Being Economy"
        }
      />

      <div className="first-gove-content">
        <div className="text-container">
          <h2>METALUCK: A STEWARD OWNED CONSCIOUS COMPANY</h2>
          <h3>
            As a conscious company, Metaluck embodies the principles of
            conscious capitalism. We firmly believe, positive change can be
            inspried by doing business.
          </h3>
          <h3>
            Conscious capitalism emphasizes businesses operating with a purpose
            beyond profit, fostering social and environmental responsibility.
          </h3>
          <h3>
            The stewardship model for businesses prioritizes responsible
            management, equitable governance, and sustainable practices to serve
            the interests of all stakeholders.
          </h3>
        </div>
        <div className="image-container">
          <img className="pattern" src={pattern} alt="pattern" />
          <img className="office-img" src={images.employee2} alt="Employee" />
        </div>
      </div>

      <div className="second-gove-content">
        <h2>KEY COMPONENTS OF STEWARDSHIP MODEL</h2>
        <div className="key-component">
          <KeyComponentCard
            image={images.shardOwnership}
            altText={"Assisting someone in climbing"}
            title="Shared ownership"
            text="Metaluck is collectively owned by all its employees who are
          considered as stewards entrusted with the resources of the
          company."
          />
          <KeyComponentCard
            image={images.Intergenerational}
            altText={"Hands of three generations"}
            title="Intergenerational"
            text="Metaluck functions with a long-term oriented financing structures
          that protects the company's purpose and independence from profit
          oriented investors."
          />
          <KeyComponentCard
            image={images.definedPurpose}
            altText={"Compass placed on a rock"}
            title="Defined purpose"
            text="Metaluck is driven by is purpose, which is to to make circular
          innovation a cornerstone for global wellbeing and profits are not
          the primary driving force of the company."
          />
        </div>
      </div>

      <div className="third-governance-content">
      <div className="text-container">
      <h3>HOW DO STEWARDSHIP MODELS SECURE FINANCING?</h3>
          <p>
            Stewardship models aim to safeguard the autonomy of organizations
            and/or assets.
            
          </p>
          <p>This poses challenges for many conventional growth equity financing
            methods, as they often demand; shown in the diagram.</p>
          <p>
                  Equity financing options aligned with stewardship typically
                  involve royalty-based returns (e.g., a percentage of revenue,
                  cash flow, or profit) or a "structured exit," where investors
                  agree to a buyout at a predetermined multiple of their initial
                  investment. 
                </p>
                <p>Debt also complement stewardship models.  A combination of debt financing, and pre-structured equity forms
                  often proves most effective for stewardship models.</p>
      </div>

      <div className="circle-contanier">
      <FaArrowLeft onClick={handlePrevious}/>

<div className="circle-navigation-container">
  
<div className="circle-wrapper">
  <svg viewBox="0 0 36 36" className="circular-chart">
    <path
      className="circle-bg"
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path
      className="circle"
      strokeDasharray={`${progress}, 100`}
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
  </svg>
  <div className="circle-text">
    <span>{circles[currentIndex].text}</span>
  </div>
</div>

</div>
<FaArrowRight onClick={handleNext}/>
      </div>

      </div>

      <div className="fourth-governance-content">
      <div className="text-container">
          <h3>STEWARDSHIP IN PRACTICE</h3>
          <p>
            The golden share model in Stewardship establishes steward-ownership
            within a company, separating voting rights from economic rights to
            ensure decisions align with the company's purpose rather than
            individual financial interests.
          </p>

            <div>
              <p>
                Those with voting rights act as stewards and cannot extract
                profits or assets, with shares passed to active company members
                to prevent speculation.
              </p>
              <p>
                A golden share, held by an independent entity like a governing
                Foundation, ensures the company's stewardship principles remain
                intact by vetoing changes to its statutes. The majority of
                voting rights are held by active founders, while profits can be
                reinvested, retained, or donated after covering capital costs.
              </p>
            </div>
        
        </div>
      </div>

      <BottomServiceCard
        heading="SERVICES"
        image={images.homeFlipImg}
        btnText="Services"
        onClick={handleServiceBtn}
      />
    </>
  );
};
