import './FooterCss.scss'
import worldMap from "../../assets/wm3.png"
import FooterLogo from "../../assets/logo/nav-logo.png"
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


export  const Footer = ()=>{
    const navigate = useNavigate();
    const  linkedinUrl ="https://www.linkedin.com/company/metaluck-global-resources-dmcc/mycompany/";
    const handleLinkedInClick = (e) => {
        window.open(linkedinUrl, "_blank");
      };
    
      const handleServicesBtn = (serviceId) => {
        navigate(`/service#${serviceId}`);
      };
    return(
        <div className="footer-container">
            <img className="footer-map map-pc" src={worldMap}/>
            {/*<img className="footer-map map-mobile" src={worldMapMobile}/>*/}
            <div className="footer-content">
               <div className="first-row">
                <div className="footer-logo-container">
                    <img className="footer-logo" src={FooterLogo} alt="metaluck-footer-logo"/>
                    <p>Metaluck Global Resources DMCC has been incorporated in the prestigious multicommodity center in dubai a key strategic location to serve our customers and suppliers</p>
                </div>
                <div className="footer-products">
                   <h3>SERVICES</h3>
                   <a onClick={() => handleServicesBtn('alternative-raw-materials')}>Alternative Raw Materials</a>
                   <a onClick={() => handleServicesBtn('hazardous-waste-management')}>Hazardous Waste Management</a>
                   <a onClick={() => handleServicesBtn('energy-efficiency')}>Energy Efficiency</a>
                   <a onClick={() => handleServicesBtn('by-products')}>By-Products</a>
                </div>
                <div className="footer-quick-links">
                   <h3>QUICK LINKS</h3>
                   <a href='/about'>About</a>
                   <a href='/resources/posts'>Posts</a>
                   <a href='/about/team'>Our Team</a>
                   <a href='/contact'>Contact Us</a>
                </div>
                <div className="footer-contact-us">
                   {/* <h3>CONTACT US</h3> */}
                   <p><b>OFFICE: </b> <br/>No 1102, <br/>Tiffany Tower,<br/> Cluster W Jumeirah Lake Towers<br/> Dubai, United Arab Emirates</p>
                   {/* <p> <b>Our Head Office:</b><br/> Unit No: 4173,DMCC<br/> Business Centre,<br/>Level No 1, Jewellery &<br/> Gemplex 3, Dubai,<br/>United Arab Emirates</p> */}
                   {/* <p> <b>Email:</b> admin@metalucks.com</p> */}

                </div>
               </div>
            <div>
                <div className="second-row">
                    <FaLinkedin onClick={handleLinkedInClick}/>
                </div>
               <hr/>
               <p className="copyright">Copyright &copy; 2024 OMNITRIX All rights reserved</p>
            </div>
            </div>

        </div>
    )
}