import React from "react";
import ContactCard from "../cards/ContactCard/ContactCard";
import HeaderComponent from "../header/HeaderComponent";
import { useNavigate } from 'react-router-dom';
import { MdPostAdd } from "react-icons/md";
import { images } from "../../utils/OtherImages";
import './PostCss.scss';

export const Posts = () => {
  const navigate = useNavigate();

  const handleContactBtn = () => {
    navigate('/contact');
  };

  const posts = [
    {
      linkedInPost: 'https://www.linkedin.com/feed/update/urn:li:activity:7068888955391291392/',
      title: 'Achieving True Circularity in the Renewable Energy Industry',
      author: 'Shamla Yoosoof',
      image: "https://media.licdn.com/dms/image/D4D12AQFSWhPb4JCxEw/article-cover_image-shrink_720_1280/0/1685352842133?e=1722470400&v=beta&t=TnTsYOtpmrDiCwMi9p7o4wf6vG2rao9rnwwkHvnOix4",
    },
    {
      linkedInPost: 'https://www.linkedin.com/posts/marco-canzian_metaluckglobalresources-environmentalsafety-ugcPost-7184183091123408896-hpdW?utm_source=share&utm_medium=member_desktop',
      title: 'Environmental Stewardship',
      author: 'Marco Canzian',
      image: 'https://media.licdn.com/dms/image/D4D22AQEACyCelV-tIQ/feedshare-shrink_2048_1536/0/1712842722848?e=1720051200&v=beta&t=m00so5d9okHjMwnZgghryHlYtb3Lld3kLEJuFB909I4',
    },
    {
      linkedInPost: 'https://www.linkedin.com/posts/marco-canzian_hazardouswastemanagement-metalindustry-sustainability-activity-7171051909468868610-1wAg?utm_source=share&utm_medium=member_desktop',
      title: 'Navigating Hazardous Waste in the Metal Sector',
      author: 'Marco Canzian',
      image: 'https://media.licdn.com/dms/image/D5610AQF6n5QH9afeaQ/image-shrink_800/0/1709712006134?e=1717855200&v=beta&t=nodT2ncZ5gxby7s639dT06iyejiuMwWvhEi7wh_NkG4',
    },
    {
      linkedInPost: 'https://www.linkedin.com/posts/sana-nasr1_metaluckglobalresources-sustainability-steelindustry-activity-7175402874758164480-XxGF?utm_source=share&utm_medium=member_desktop',
      title: 'My journey with Metaluck Global Resources DMCC',
      author: 'Sana Nasr',
      image: 'https://media.licdn.com/dms/image/D4E22AQEl9JXVhtaZgA/feedshare-shrink_800/0/1710749356714?e=1720051200&v=beta&t=5FfVrEKCNNrbaOelxhZUXbgDqNTOXn50qRRynrvoOlc',
    },
  ];

  const handleLinkedInClick = (linkedInPost) => {
    window.open(linkedInPost, "_blank");
  };

  return (
    <>
      <HeaderComponent 
        image={images.waste}
        text={"POSTS"}
      />

      <div className="posts-container">
        <div className="heading">
          {/* <h2>POSTS</h2> */}
        </div>
        {posts.length > 0 ? (
          <div className="posts-grid">
            {posts.map((post, index) => (
              <div key={index} onClick={() => handleLinkedInClick(post.linkedInPost)} className="post-card">
                <div className={`image-container`}>
                  <img src={post.image} alt={post.title} />
                </div>
                <div className="post-details">
                  <p className="author">{post.author}</p> 
                  <h3>{post.title}</h3>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-posts">
            <div className="circle">
              <MdPostAdd />
            </div>
            <p>No Posts yet</p>
          </div>
        )}
      </div>

      <ContactCard
        heading={`CONTACT US`}
        image={images.contactus}
        btnText={`Contact Us`}
        onClick={handleContactBtn}
      />
    </>
  );
};

export default Posts;
