import React, { useState } from "react";
import pattern from "../../assets/about-shape.png";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import './ImpactCss.scss'
import HeaderComponent from "../header/HeaderComponent";
import ContactCard from "../cards/ContactCard/ContactCard";
import { useNavigate } from "react-router-dom";
import { GiNuclearWaste } from "react-icons/gi";
import { LiaUserGraduateSolid } from "react-icons/lia";
import { BsCartCheckFill } from "react-icons/bs";
import { GiWireframeGlobe } from "react-icons/gi";
import { GiSteelClaws } from "react-icons/gi";
import { FaInfinity } from "react-icons/fa";
import { images } from "../../utils/OtherImages";

export const Impact = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const handleContactBtn = () => {
    navigate("/contact");
  };
  const handleLearnMoreBtn = () => {
    navigate("/about");
  };
  const stats = [
    {
      icon: <GiNuclearWaste />,
      value: "75K MT",
      description: "Hazardous waste exported to date",
    },
    {
      icon: <p>Fe</p>,
      value: "21K MT",
      description: "Green Iron produced from hazardous waste",
    },
    {
      icon: <LiaUserGraduateSolid />,
      value: "30 Students",
      description: "Empowered with education for employment in Palestine",
    },
    {
      icon: <GiWireframeGlobe />,
      value: "Investment in GSP",
      description:
        "A platform for community sourced solution for the steel and metal industry",
    },
   
  ];

const stats2=[
  {
    icon: <p>Zn</p>, // Replace with appropriate icons
    value: "19K MT",
    description: "Green Zinc produced from hazardous waste",
  },
  {
    icon: <GiSteelClaws />, // Replace with appropriate icons
    value: "1 Mn MT",
    description: "Iron by products recycled",
  },
  {
    icon: <BsCartCheckFill />,
    value: "20+ Clients",
    description: "Steel producers served across three continents",
  },
  {
    icon: <FaInfinity />,
    value: "Investment in Fortotec",
    description:
      "Up cycling vegetable waste into flavor enhancers & protein additives",
  },
];
  const stats3 = [
    {
      value: "~900 tons",
      description: "Hazardous waste removed from the environment every month",
    },
    {
      value: "~45,000 tons",
      description:
        "Hazardous waste free steel production supported every month",
    },
    {
      value: "Eliminating",
      description: "Lead, CL and CD heavy metals - Soil, Water and Air",
    },
    {
      value: "Empowering",
      description:
        "30 students from vulnerable communities with education for employment",
    },
    {
      value: "~470 tons",
      description: "Green Iron produced from Hazardous waste every month",
    },
    {
      value: "~240 tons",
      description: "Green Zinc produced from Hazardous waste every month",
    },
    {
      value: "Developing",
      description:
        "An Investment ecosystem that can shift mindsets and capital towards positive impact",
    },
    { value: 'Investments', 
    description: 'In technologies that repurpose compost waste into: Protein alternatives, Flavor enhancers, Renewable energy, Fertilizer' }
  ];
  return (
    <>
      <HeaderComponent image={images.waste} text={"IMPACT"} />
      <div className="zeroth-impact-content">
       <div className="sub-impact-content">
       <div className="text-container">
          <h2>INSPIRING COLLABORATION TOWARDS CHANGE</h2>
          <h3>"Global Steel Producers" (GSP) is a pioneering platform where the titans of steel converge to shape the future of the industry.</h3>
          <h3>Designed as a vibrant community for the exchange of revolutionary ideas and cutting-edge practices, GSP brings together the world’s leading steel experts and producers in a dynamic environment.
            By participating in GSP, members contribute to the advancement of global steel production and empower themselves and their organizations to achieve new heights of excellence and sustainability.</h3>
            <h3>Be part of the movement that is forging the steel of tomorrow, today!</h3>
          <button onClick={handleLearnMoreBtn}>
            <IoIosArrowDroprightCircle /> Coming Soon
          </button>
        </div>
        <div className="image-container">
          <img className="pattern" src={pattern} alt="patern" />
          <img className="office-img" src={images.gspLogo} alt="GSP Logo" />
        </div>
       </div>
      </div>


      <div className="impact-contaner">
        <h2>OUR BUSINESS IMPACT</h2>
        <div className="first-impact-content">
          {stats.map((stat, index) => (
            <div className="each-container" key={index}>
              <div className="icon">{stat.icon}</div>
              <div className="text">
                <div className="value"><h2>{stat.value}</h2></div>
                <div className="desc"><h3>{stat.description}</h3></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="middle-image-content">
       
      </div>
      
      <div className="impact-contaner">
        <h2>OUR BUSINESS IMPACT</h2>
        <div className="first-impact-content">
          {stats2.map((stat, index) => (
            <div className="each-container" key={index}>
              <div className="icon">{stat.icon}</div>
              <div className="text">
              <div className="value"><h2>{stat.value}</h2></div>
              <div className="desc"><h3>{stat.description}</h3></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="second-impact-content">
       <div className="sub-impact-content">
       <div className="text-container">
       <h2>OUR BUSINESS IMPACT AT A GLANCE</h2>
          </div><div className="stat-main-box"> 
          {stats3.map((stat, index) => (
            <div className="stat-box" key={index}>
              <h3><TiTick /></h3>
              <h3>              
               {stat.value} - {stat.description}
              </h3>
            </div>
          ))}
          
        </div>
        <div className="image-container">
          <img className="pattern" src={pattern} alt="patern" />
          <img className="office-img" src={images.employee} alt="office" />
        </div>
       </div>
      </div>

      <div className="second-impact-container">
        <h2>OUR BUSINESS IMPACT AT A GLANCE</h2>
        <div className="stats-grid">
          {stats3.map((stat, index) => (
            <div className="stat-box" key={index}>
              <h2>
                {/* <TiTick /> *
                {stat.value} - {stat.description}
              </h2>
            </div>
          ))}
        
        </div>
      </div> */}
      <ContactCard
        heading={`CONTACT US`}
        image={images.contactus}
        btnText={`Contact Us`}
        onClick={handleContactBtn}
      />
    </>
  );
};

export default Impact;
