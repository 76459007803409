import { useState } from "react";
import './TeamCss.scss';
import HeaderComponent from "../header/HeaderComponent";
import office from "../../assets/metaluckImages/teamPageBanner.jpg";
import TeamMemberCard from '../cards/TeamMemberCard/TeamMemberCard';
import '../../utils/TeamMemberImages';
import { images } from "../../utils/TeamMemberImages"
import { useNavigate } from 'react-router-dom';
import container from  '../../assets/metaluckImages/homeFlipImg.jpg'
import BottomServiceCard from '../../view/cards/BottomServiceCard/BottomServiceCard'
export const Team = () => {
  const [expandedCard, setExpandedCard] = useState(null);
  const navigate = useNavigate();

  const handleServiceBtn = () => {
    navigate('/service');
  };

  const handleToggle = (index) => {
    if (expandedCard === index) {
      setExpandedCard(null); // Collapse the card if clicked
    } else {
      setExpandedCard(index); // Expand the clicked card
    }
  };

  const teamMembers = [
    {
      linkedinUrl : "https://www.linkedin.com/in/alexander-alegria-seif-16b66088?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      image: images.Alex,
      altText :"Alex Alegria Seif",
      name: "Alex Alegria Seif",
      position: "President",
      description: "I've long been on a quest for my Ikigai, seeking the perfect alignment of my purpose—using my talents for the betterment of humanity—my passion, the value I can add to others, and the means to sustain it financially. This search was challenging until I founded Metaluck. Now, I view this endeavour not just as a business, but as an evolution of consciousness. Metaluck represents more than a company; it's a beacon for those striving to find their own intersections of purpose, joy, utility, and reward."
    },
    {
      linkedinUrl: "https://www.linkedin.com/in/drake-reno/",
      image: images.Drake,
      altText:"Drake Reno",
      name: "Drake Reno",
      position: "VP Business Development",
      description: "We are here to live with joy and purpose. Metaluck is the only company in my entire career I have ever experienced that actually operates on these values and plays by a totally different set of rules. We are a company of owners that hold sacred the duty to be a model for all businesses, a beautiful example of how companies can simultaneously raise consciousness and do well by doing good for ALL people and the Planet. Metaluck represents the next stage in my personal and professional journey and has awakened me to what is possible in ways beyond what I ever imagined. Being part of this organization has been one of the greatest blessings in my life."
    },
    {
      linkedinUrl: "https://www.linkedin.com/in/marco-freire-santos/",
      image: images.Marco_f,
      altText:"Marco Freire",
      name: "Marco Freire",
      position: "VP Operation & Logistics",
      description: "Why am I at Metaluck? Because it's an extension of my family, where dreams of a better future for all come true. We embrace free thought, brotherly love, and meritocracy. Leaders share knowledge, encouraging everyone to shine and pursue their dreams. Why am I at Metaluck? Because it reflects the founder’s culture. He is my mentor and friend, and the mentor of a team dedicated to evolving consciousness and promoting equality. We call each other brothers, united by solid principles, a clear strategy, and a collective journey towards personal growth and impacting humanity."
    },

    {
      linkedinUrl: "https://www.linkedin.com/in/shamla-yoosoof-201b0b211/",
      image: images.Shamla,
      altText:"Shamla Yoosoof",
      name: "Shamla Yoosoof",
      position: "VP Finance & Impact Measurement",
      description: "Being part of Metaluck has been beyond empowerment, it has become so integral in my life, that I don't see it separate from my self. It is because my personal conscious evolution started with Metaluck's journey. I do not see Metaluck as work, I see Metaluck as an inspiration and a way of life. I don't feel that I'm part of an organization, rather, I am the organization."
    },
    {
      linkedinUrl: "https://www.linkedin.com/in/marco-canzian/",
      image: images.Marco_c1,
      altText: "Marco Canzian",
      name: "Marco Canzian",
      position: "Managing Partner R&D, Business Development Manager",
      description: "At Metaluck, I am driven by the chance to connect and innovate with like-minded people, committed to improving the well-being of the people and the planet. Working with a diverse team, I enjoy operating with a diverse and dynamic team that launches us toward groundbreaking solutions in sustainability and the circular economy. Every challenge here is a chance to make a positive impact, shaping a better future for the next generations and all the people."
    },
    {
      linkedinUrl: "https://www.linkedin.com/in/sumayyah-hamed-09099366/",
      image: images.Sumaiya,
      altText:"Sumayyah Hamed",
      name: "Sumayyah Hamed",
      position: "Managing Partner Logistics",
      description: "Joining Metaluck Global Resources is about embracing a powerful mission that deeply resonates with me. Metaluck champions a regenerative economy, prioritizing planetary and human well-being. What draws me deeply to this company is our shared ethos, epitomized by the Metaluck Creed.  This creed aligns us, the universal harmony and it underlines the significance of each action towards sustainable and compassionate outcomes. At Metaluck, we're stewards of the Earth, committed to nurturing and protecting all life forms."
    },
    {
      linkedinUrl: "https://www.linkedin.com/in/carolina-paredes-210101197/",
      image: images.Carolina,
      altText:"Carolina Paredes",
      name: "Carolina Paredes",
      position: "Managing Partner Environmental Compliance",
      description: "I am in the company with a firm conviction to be the change our world needs. My goal is to actively contribute to building a sustainable future, where environmental, economic, and social aspects are balanced. I believe that every small action matters, and by combining efforts, we can achieve significant impact. From my position, I am committed to promoting responsible practices, driving innovation, and fostering collective awareness about the importance of preserving our planet for future generations. Together, we can make a difference and leave a positive legacy for generations to come."
    },
    {
      linkedinUrl: "https://www.linkedin.com/in/stephen-amrita/",
      image: images.Amrita,
      altText:  "Amrita Stephen",
      name: "Amrita Stephen",
      position: "Managing Partner Accounting",
      description: "I am gratified for being in an environment where integrity, honesty, and social responsibility are not just ideals, but guiding principles. I believe that businesses are responsible for positively impacting their communities and the world at large, and being part of Metaluck which shares this belief allows me to contribute meaningfully to something greater than myself. Knowing that our decisions and actions are grounded in moral integrity gives me confidence that we are not only pursuing success but doing so in an ethical and sustainable way."
    }
  ];

  return (
    <>
      <HeaderComponent image={office} text={"OUR TEAM"} />
      <div className='team'>
            {teamMembers.map((member, index) => (
              <TeamMemberCard
                key={index}
                image={member.image}
                name={member.name}
                position={member.position}
                description={member.description}
                linkedinUrl={member.linkedinUrl}
                isExpanded={expandedCard === index}
                onToggle={() => handleToggle(index)}
              />
            ))}
      </div>
      <BottomServiceCard
        heading={`SERVICES`}
        image={container}
        btnText={`Services`}
        // isActive={isActive}
        onClick={handleServiceBtn}
      />
    </>
  );
};

