import React from "react";
import './KeyComponentCardCss.scss'

function KeyComponentCard ({ image, title, text, altText }) {
  return (
    <div className="each-component-card">
      <div className="overlay-card"></div>
      <div className="image">
      <img className="office-img" src={image} alt={altText} />
      </div>
      <div className="title"><h3>{title}</h3></div>
      <div className="text"><p>{text}
      </p></div>
    </div>
  );
}
export default KeyComponentCard
