import React from 'react';
import './FlipCardCss.scss'
import { MdOutlineFlipCameraAndroid } from "react-icons/md";

function FlipCard({ icon,cardFrontText, office, text, heading }) {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
        <div className='text-container'>
            <p className='icon'>{icon}</p>
            <p>{cardFrontText}</p>
            <div className="flip-icon" ><MdOutlineFlipCameraAndroid /><h3>FLIP ME</h3></div>

            </div>
          {/* <img className="pattern" src={pattern} alt="pattern" /> */}
          {/* <img className="office-img" src={office} alt="office" /> */}
          <div className="image-overlay"></div>
        </div>
        <div className="flip-card-back">
          <div className="text-container">
            <h2>{heading}</h2>
            <h3>{text}</h3>
           
            <div className="image-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;
