import React, { useState, useRef } from "react";
import './ContactusCss.scss'
import pattern from "../../assets/about-shape.png";
import HeaderComponent from "../header/HeaderComponent";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import emailjs from '@emailjs/browser';
import Popup from "../cards/PopupCard/Popup";
import { images } from "../../utils/OtherImages";

const services = [
  "Alternative Raw Materials",
  "Energy Efficiency",
  "Hazardous Waste",
  "By Products",
  "Other",
];

const initialFormData = {
  name: "",
  surname: "",
  email: "",
  organization: "",
  service: "",
  note: "",
};



const SERVICE_ID = 'service_pv92hlh';
const TEMPLATE_ID = 'template_2fpxy25';
const PUBLIC_KEY = 'MMtM-2vDuCrbbusMb';

export const ContactUs = () => {
  const [formData, setFormData] = useState(initialFormData);
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleServiceSelect = (service) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      service: service,
    }));
    setDropdownOpen(false);
    console.log(`Selected service: ${service}`);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then(
        () => {
          console.log('SUCCESS!');
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
            setFormData(initialFormData);
          }, 5000);
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setFormData(initialFormData);
  };

  return (
    <>
      <HeaderComponent image={images.waste} text={"CONTACT US"} />
      <div className="first-contact-content">
        <div className="text-container">
          <h2>GET IN TOUCH</h2>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-content1">
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="surname"
                    name="surname"
                    placeholder="Surname"
                    value={formData.surname}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="organization"
                    name="organization"
                    placeholder="Organization"
                    value={formData.organization}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <div
                  className="dropdown"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <div className={`dropdown-header ${formData.service ? 'selected' : ''}`}>
                    {formData.service || "Select a service"}{" "}
                    {dropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </div>
                  {dropdownOpen && (
                    <ul className="dropdown-list">
                      {services.map((service) => (
                        <li
                          key={service}
                          onClick={() => handleServiceSelect(service)}
                        >
                          {service}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Hidden input to include the selected service in the form data */}
                <input
                  type="hidden"
                  name="service"
                  value={formData.service}
                  readOnly
                />
              </div>
            </div>

            <div className="form-group">
              <textarea
                id="note"
                name="note"
                placeholder="Note"
                value={formData.note}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div>
              <button type="submit" value="Send">Submit</button>
            </div>
            {showPopup && <Popup message="Email has been sent." onClose={handlePopupClose} />}
          </form>
        </div>
        <div className="image-container">
          <img className="pattern" src={pattern} alt="pattern" />
          <img className="office-img" src={images.employee} alt="Employee" />
        </div>
      </div>
    </>
  );
};
