import React from 'react';
import './ServiceContentCardCss.scss'
import pattern from "../../../assets/about-shape.png";

const ServiceContentCard = ({ title, items, description, image,altText }) => {
  return (
    <div className="service-content">
      <div className="text-container">
        <h2>{title}</h2>
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h3>{description}</h3>
      </div>
      <div className="image-container">
        {/* <img className="pattern" src={pattern} alt="pattern" /> */}
        <img className="office-img" src={image} alt={altText} />
      </div>
    </div>
  );
};

export default ServiceContentCard;
