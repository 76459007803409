import {React, useState} from "react";
import './AboutCss.scss'
import pattern from "../../assets/about-shape.png";
import FlipCard from "../cards/FlipCard/FlipCard";
import ContactCard from "../cards/ContactCard/ContactCard";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import HeaderComponent from "../header/HeaderComponent";
import { useNavigate } from 'react-router-dom';
import KeyComponentCard from "../cards/KeyComponentCard/KeyComponentCard";
import { images } from "../../utils/OtherImages";
import { FaFistRaised } from "react-icons/fa";
import { AiOutlineSolution } from "react-icons/ai";


export const About = () => {

  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();


  const handleContactBtn = () => {
    // setIsActive(true);
    navigate('/contact');

  };
  const handleLearnMoreBtn = () => {
    navigate('/sustainability-and-impact')
  }
  return (
    <>
     <HeaderComponent 
     image={images.temHead}
     text={"ABOUT"}
     />
      <div className="first-about-content">
        <div className="text-container">
        <h2>WELCOME TO A PURPOSE-DRIVEN COMPANY</h2>
          <h3>
           Metaluck Global Resources is
            reshaping the future of the metallurgical industry by fostering a
            sustainable circular economy.
          </h3>
          <h3>
            Explore our initiatives, learn about our groundbreaking processes,
            and see how together, we can support the well-being of our planet
            and communities.
          </h3>
          <div className="image-container-mobile">
          <img className="office-img" src={images.waste} alt="Our Work" />
        </div>
          <button onClick={handleLearnMoreBtn}><IoIosArrowDroprightCircle /> Learn More</button>
        </div>
        <div className="image-container">
          <img className="pattern" src={pattern} alt="patern" />
          <img className="office-img" src={images.waste} alt="Our Work" />
        </div>
        
      </div>
      <div className="second-about-content">
        <div className="sub-second-about">
            <div className="image-container">
              <FlipCard
              icon={<FaFistRaised/>}
               cardFrontText={"EMPOWER"}
                pattern={pattern}
                office={images.vision}
                heading="OUR VISION"
                text={`"To live in a world transformed by a
                regenerative economy for the wellbeing of
                Planet & People."`}
               />
            </div>
            <div className="image-container">
              <FlipCard
                icon={<AiOutlineSolution />}
                cardFrontText = {"SOLUTION"}
                pattern={pattern}
                office={images.mission}
                heading="OUR MISSION"
                text={`"We provide sustainable solutions to
                the metallurgical industry by
                Converting waste into Raw materials."`}
              />
            </div>
        </div>

        <div className="sub-second-about-mobile">
        <div className="key-component">
        <KeyComponentCard
            image={images.vision}
            altText={"Stages of seed growth"}
            title={`Vision`}
            text={`To live in a world transformed by a regenerative economy for the wellbeing of planet & People.`}
          />
           <KeyComponentCard
            image={images.mission}
            altText={"Employees"}
            title={`Mission`}
            text={`We provide sustainable solutions to the metallurgical industry by Converting waste into Raw materials.`}
          />
        </div>
        </div>
      
      <div className="second-about-content-mobile">
        <div class="card">
        <div class="text">
          <h2>OUR VISION</h2>
          <p>{`"To live in a world transformed by a regenerative economy for the wellbeing of Planet & People."`}</p>
        </div>
      </div>
      <div class="card">
        <div class="text">
          <h2>OUR MISSION</h2>
          <p>{`"We provide sustainable solutions to the metallurgical industry by Converting waste into Raw materials."`}</p>
        </div>
        </div>

      </div>
      </div>
      <div className="third-about-content">
      <h2>METALUCK BRANCHES</h2>

      <div className="image-container">
      <img src={images.map} alt="World Map with Blue Dots" className="office-img"/>
        </div>      
      </div>
      <ContactCard
        heading={`CONTACT US`}
       image={images.contactus}
      btnText={`Contact Us`}
      //isActive={isActive}
      onClick={handleContactBtn}
      />
    </>
  );
};
