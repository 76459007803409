import React from "react";
import ContactCard from "../cards/ContactCard/ContactCard";
import HeaderComponent from "../header/HeaderComponent";
import { useNavigate } from 'react-router-dom';
import { MdOutlineArticle } from "react-icons/md";
import './ArticleCss.scss';
import { images } from "../../utils/OtherImages";
import pdfEmployees from '../../assets/pdfDocs/Metaluck-Handbook-Employees.pdf';
import pdfEmployees1 from '../../assets/pdfDocs/About-Metaluck-The-Journey-of-a-Conscious-Organization.pdf';

export const Articles = () => {
  const navigate = useNavigate();

  const handleContactBtn = () => {
    navigate('/contact');
  };

  const handleLinkedInClick = (linkedInPost) => {
    window.open(linkedInPost, "_blank");
  };

  const articles = [
    // {
    //   linkedInPost: 'https://www.linkedin.com/feed/update/urn:li:activity:7068888955391291392/',
    //   title: 'Achieving True Circularity in the Renewable Energy Industry',
    //   content: 'content goes here...',
    //   image: "https://media.licdn.com/dms/image/D4D12AQFSWhPb4JCxEw/article-cover_image-shrink_720_1280/0/1685352842133?e=1722470400&v=beta&t=TnTsYOtpmrDiCwMi9p7o4wf6vG2rao9rnwwkHvnOix4",
    // },
  ];

  return (
    <>
      <HeaderComponent 
        image={images.waste}
        text={"ARTICLES"}
      />
      <div className="first-article-content">
        <div className="heading">
          {/* <h2>RECENT ARTICLES</h2> */}
        </div>
        {articles.length > 0 ? (
          <div className="articles-list">
            {articles.map((article, index) => (
              <div key={index} className="article-item" onClick={() => handleLinkedInClick(article.linkedInPost)} >
                <div className="image-container">
                  <img src={article.image} alt={article.title} className="article-thumbnail" />
                </div>
                <div className="article-details">
                  <h3>{article.title}</h3>
                  <p>{article.content}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-article">
            <div className="circle">
              <MdOutlineArticle />
            </div>
            <p>No Articles yet</p>
          </div>
        )}
      </div>
      <div className="other-resources-content">
        <div className="heading">
          <h2>OTHER RESOURCES</h2>
        </div>
        <div className="resources-list">
          <div className="resource-item">
            <div className="image-container">
              <img src={images.MetaluckHandbookTemplate} alt="Metaluck Handbook Employees template" className="pdf-thumbnail" />
            </div>
            <div className="resource-details">
              <h3>Metaluck Handbook Employees</h3>
              <a href={pdfEmployees} target="_blank" rel="noopener noreferrer" className="download-btn">Preview and Download</a>
            </div>
          </div>
          <div className="resource-item">
            <div className="image-container">
              <img src={images.MetaluckJourneyTemplate} alt="About Metaluck The Journey of a Conscious Organization template" className="pdf-thumbnail" />
            </div>
            <div className="resource-details">
              <h3>About Metaluck The Journey of a Conscious Organization</h3>
              <a href={pdfEmployees1} target="_blank" rel="noopener noreferrer" className="download-btn">Preview and Download</a>
            </div>
          </div>
        </div>
      </div>
      <ContactCard
        heading={`CONTACT US`}
        image={images.contactus}
        btnText={`Contact Us`}
        onClick={handleContactBtn}
      />
    </>
  );
};
