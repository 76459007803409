import { React, useState } from "react";
import './ResourcesCss.scss'
import pattern from "../../assets/about-shape.png";
import ContactCard from "../cards/ContactCard/ContactCard";
import HeaderComponent from "../header/HeaderComponent";
import { useNavigate } from "react-router-dom";
import { images } from "../../utils/OtherImages";
export const Resources = () => {
  const navigate = useNavigate();

  const handleContactBtn = () => {
    navigate("/contact");
  };

  const handlePostBtn = () => {
    navigate("/resources/posts");
  };

  const handleArticleBtn = () => {
    navigate("/resources/articles");
  };

  return (
    <>
      <HeaderComponent image={images.waste} text={"RESOURCES"} />

      <div className="first-resource-content">
        <div className="text-container">
          <h3>
            Metaluck Global Resources is deeply committed to continual
            improvement and innovation. We believe in staying at the forefront
            of industry advancements and sustainability practices. 
          </h3>
          <h3>
          To keep our
            partners, clients, and community informed, we regularly update our
            blog and resources section with the latest news, insights, and
            breakthroughs. Stay connected with us to learn how we are advancing
            our mission and making a meaningful impact in the metallurgical
            industry. Together, let's shape a sustainable future.
          </h3>
        </div>
      </div>

      <div className="second-resource-content">
        <div className="text-container">
          
          <button onClick={handlePostBtn} className="post-btn">Posts</button>
        </div>
        <div className="image-container">
          <img className="pattern" src={pattern} alt="pattern" />
          <img className="office-img" src="https://media.licdn.com/dms/image/D4D12AQFSWhPb4JCxEw/article-cover_image-shrink_720_1280/0/1685352842133?e=1722470400&v=beta&t=TnTsYOtpmrDiCwMi9p7o4wf6vG2rao9rnwwkHvnOix4" alt="Wind Farm" />
        </div>
      </div>
      <div className="third-resource-content">
       
        <div className="image-container">
          <img className="pattern" src={pattern} alt="pattern" />
          <img className="office-img" src={images.MetaluckHandbookTemplate} alt="Metaluck Handbook Template" />
        </div>
        <div className="text-container">
          
          <button onClick={handleArticleBtn} className="post-btn">Articles</button>
        </div>
      </div>

      <ContactCard
        heading={`CONTACT US`}
        image={images.contactus}
        btnText={`Contact Us`}
        onClick={handleContactBtn}
      />
    </>
  );
};
